main {
  form.searchForm {
    margin: 48px 0 0;
    input[type="text"] {
      width: 80%;
      margin: 0 auto;
      padding: 12px 0;
      font-size: 36px;
      border: none;
      font-weight: 300;
      border-bottom: 1px solid rgba(black, 0.5);
      outline: none;
    }
  }
  .btn {
    border: none;
    outline: none;
    padding: 12px 24px;
    &--fetchMore {
      background: #1dd1a1;
      color: #fff;
      margin: 48px 0 0 0;
      transition: all linear 0.5s;
      border-radius: 7px;
      &:hover {
        cursor: pointer;
        background: darken(#1dd1a1, 10%);
      }
    }
  }
}

//Responsive
@media screen and (max-width: 1024px) {
  form.searchForm {
    input[type="text"] {
      font-size: 1.5em !important;
    }
  }
}

@media screen and (max-width: 779px) {
  form.searchForm {
    input[type="text"] {
      width: 100% !important;
      font-size: 1em !important;
    }
  }
}
