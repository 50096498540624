//Reset
html,
body,
ul {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}
body {
  display: flex; //for ie only
}

#root {
  display: flex;
  display: -ms-flexbox;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  -ms-flex-direction: column;
}
//Common use
.container {
  width: 80%;
  max-width: 1280px;
  margin: auto;
}
.text {
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
}

//Grid
.row {
  display: flex;
  display: -ms-flexbox;
  .col {
    flex: 1;
  }
}

//Layout & Image Grid
main {
  flex-grow: 1;
}

.imgGrid {
  margin: 48px 0 0 0;
  ul {
    // column-count: 4;
    // column-gap: 1em;
    li {
      position: relative;
      display: inline-block;
      width: 24%;
      min-height: 250px;
      list-style: none;
      box-sizing: border-box;
      margin-right: 0.5%;
      > img {
        max-width: 100%;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        .favourite {
          opacity: 0.5;
        }
      }

      .favourite {
        position: absolute;
        bottom: 12px;
        right: 12px;
        opacity: 0;
        &--active {
          opacity: 1 !important;
        }
        img {
          max-width: 40px;
        }
      }
    }
  }
}

//Media query for responsive layout
@media screen and (max-width: 1024px) {
  .imgGrid {
    ul {
      li {
        width: 29% !important;
      }
    }
  }
}

@media screen and (max-width: 779px) {
  .container {
    width: 85% !important;
  }
  .imgGrid {
    ul {
      li {
        width: 49% !important;
      }
    }
  }
}

@media screen and (max-width: 557px) {
  .container {
    width: 90% !important;
  }
  .imgGrid {
    ul {
      li {
        width: 100% !important;
      }
    }
  }
}
