header {
  box-shadow: 0 0 30px rgba(black, 0.08);
  flex-shrink: 0;
  .container {
    display: flex;
    padding: 24px 0;
    font-size: 24px;
    flex-wrap: wrap;
    .logo {
      width: 150px;
      border-right: 1px solid rgba(black, 0.3);
      margin: 0 12px 0 0;
      flex-shrink: 0;
    }
    .navigation {
      flex-grow: 1;
      //   margin: 0 0 0 12px;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
          list-style: none;
          padding: 0 24px;
          a {
            text-decoration: none;
            color: rgba(black, 0.3);
            &.active {
              color: rgba(black, 0.8);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 557px) {
  header {
    .logo {
      border: none !important;
      width: 100% !important;
      text-align: center !important;
      margin: 0 0 24px 0 !important;
    }
    .navigation {
      ul {
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
}
